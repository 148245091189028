<template>
  <div class="verify-code">
    <login-input class="verify-code-input" type="text" :icon="phoneIcon" placeholder="手机号"
      @onInput="onPhoneNumberInput" />
    <div class="verify-code-top">
      <login-input class="verify-code-top-input" type="password" :icon="msgIcon" placeholder="验证码"
        @onInput="onVerifyCodeInput" />
      <button class="verify-code-top-button" :class="{ disable: isCountingDown }" @click="onSendCodeClick">
        {{ codeButtonTitle }}
      </button>
    </div>
    <button class="verify-code-switch" @click="onSwithClick">
      使用密码登录
    </button>
    <button class="verify-code-login" @click="onLogin">登 录</button>
  </div>
</template>

<script>
import LoginInput from "@c/login/login-input.vue";
import { CacheTool } from "@js/cache-tool.js";
import { ValidateTool } from "@js/validate-tool.js";
import { CountdownTool } from "@js/countdown-tool.js";
export default {
  components: {
    LoginInput,
  },

  data: function () {
    return {
      phoneIcon: require("@imgs/login-phone.png"),
      msgIcon: require("@imgs/login-msg.png"),
      phoneNumber: "",
      verifyCode: "",
      countDownNumber: 0,
      currentCountDownNumber: 0,
    };
  },

  computed: {
    codeButtonTitle: function () {
      return this.countDownNumber === 0
        ? "获取验证码"
        : this.countDownNumber + "s";
    },

    isCountingDown: function () {
      return this.countDownNumber != 0;
    },
  },

  methods: {
    onSwithClick: function () {
      this.$emit("onSwithClick", "VerifyCode");
    },

    onPhoneNumberInput: function (value) {
      this.phoneNumber = value;
    },

    onVerifyCodeInput: function (value) {
      this.verifyCode = value;
    },

    onSendCodeClick: function () {
      if (this.isCountingDown === true) {
        return;
      }
      this.$toast.hide();
      if (
        this.phoneNumber === "" ||
        !ValidateTool.isPhoneNumber(this.phoneNumber)
      ) {
        this.$toast.loginShowRed(MSG.phoneNumberError);
        return;
      }
      this.sendVerifyCodeRequest();
    },

    onLogin: function () {
      this.$toast.hide();
      if (this.phoneNumber === "" || this.verifyCode === "") {
        this.$toast.loginShowRed(MSG.phoneNumberOrVerifyCodeError);
        return;
      }
      this.loginRequest();
    },

    async loginRequest() {
      const params = {
        phone: this.phoneNumber,
        verificationCode: this.verifyCode,
      };
      try {
        const response = await this.$api.verifyCodeLogin(params);
        localStorage.setItem('validPassword',true)
        this.$emit("onLoginSucceed", response);
      } catch (error) {
        this.$toast.loginShowRed(error);
      }
    },

    async sendVerifyCodeRequest() {
      const params = {
        "toPhone": this.phoneNumber,
        "smsType": 1 //八戒睡眠登录
      };
      try {
        const _ = await this.$api.sendVerifyCode(params);
        this.$toast.loginShowGreen(MSG.verifyCodeSendSucceed);
        this.countDown();
      } catch (error) {
        this.$toast.loginShowRed(error);
      }
    },

    countDown() {
      this.countDownNumber = window.MSG_LIMIT_SECOND;
      CountdownTool.countDown({
        remainSeconds: window.MSG_LIMIT_SECOND,
        trigger: (remainSeconds) => {
          this.countDownNumber = remainSeconds;
        },
        over: () => {
          this.countDownNumber = 0;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>


.verify-code {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-input {
    width: 100%;
    height: rem(44);
  }

  &-top {
    width: 100%;
    height: rem(44);
    display: flex;
    justify-content: space-between;
    margin-top: rem(27);

    &-input {
      width: rem(276);
      height: 100%;
    }

    &-button {
      color: #505659;
      background-color: white;
      font-size: rem(15);
      border: 1px solid #d9d9d9;
      border-radius: rem(3);
      width: rem(113);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .disable {
      opacity: 0.65;
    }
  }

  &-switch {
    color: #212773;
    font-size: rem(15);
    line-height: rem(21);
    align-self: flex-start;
    margin-top: rem(27);
  }

  &-login {
    color: white;
    background-color: #212773;
    font-size: rem(16);
    border-radius: rem(3);
    width: 100%;
    height: rem(44);
    margin-top: rem(29);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>