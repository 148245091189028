<template>
  <div class="password">
    <login-input class="password-input" type="text" :icon="accountIcon" placeholder="账号" @onInput="onAccountInput" />
    <login-input class="password-input bottom" type="password" :icon="passwordIcon" placeholder="密码"
      @onInput="onPasswordInput" />
    <div class="password-middle">
      <button class="password-middle-button" @click="onSwithClick">
        使用手机验证码登录
      </button>
      <button class="password-middle-button" @click="onForgetClick">
        忘记密码
      </button>
    </div>
    <button class="password-login" @click="onLogin">登 录</button>
  </div>
</template>

<script>
import LoginInput from "@c/login/login-input.vue";
import { CacheTool } from "@js/cache-tool.js";
import { encrypt } from "@js/aes-tool.js";
export default {
  components: {
    LoginInput,
  },

  data: function () {
    return {
      alert: "用户或密码错误!",
      accountIcon: require("@imgs/login-user.png"),
      passwordIcon: require("@imgs/login-password.png"),
      account: "",
      password: "",
    };
  },
  methods: {
    onSwithClick: function () {
      this.$emit("onSwithClick", "Password");
    },

    onForgetClick() {
      this.$toast.loginShowRed(MSG.functionNotOpen);
    },

    onAccountInput: function (value) {
      this.account = value;
    },

    onPasswordInput: function (value) {
      this.password = value;
    },

    onLogin: function () {
      this.$toast.hide();
      if (this.account === "" || this.password === "") {
        this.$toast.loginShowRed(MSG.accountOrPasswordError);
        return;
      }
      this.loginRequest();
    },

    async loginRequest() {
      const params = {
        userName: this.account,
        passWord: encrypt(this.password),
      };
     
      try {
        const response = await this.$api.passwordLogin(params);
     
        let validPassword = false
        if(response.validPassword == 0){
          validPassword = false
        }else {
          validPassword = true
        }
        localStorage.setItem('validPassword',validPassword)
        this.$emit("onLoginSucceed", response);
      } catch (error) {
  
        this.$toast.loginShowRed(error);
      }
    },
  }

   


};
</script>

<style lang="scss" scoped>


.password {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-input {
    width: 100%;
    height: rem(44);
  }

  .bottom {
    margin-top: rem(28);
  }

  &-middle {
    width: 100%;
    margin-top: rem(27);
    display: flex;
    justify-content: space-between;

    &-button {
      color: #212773;
      font-size: rem(15);
      line-height: rem(21);
    }
  }

  &-login {
    color: white;
    background-color: #212773;
    font-size: rem(16);
    border-radius: rem(3);
    width: 100%;
    height: rem(44);
    margin-top: rem(29);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>