<template>
  <div class="login">
    <div class="login-container">
      <div class="login-container-content">
        <div class="login-container-content-top">
          <img class="login-container-content-top-logo" src="@imgs/logo-bajie.png" />
          <div class="login-container-content-top-right">
            <p class="login-container-content-top-right-title">
              八戒睡眠管理平台
            </p>
            <p class="login-container-content-top-right-info">
              Dodge sleep management platform
            </p>
          </div>
        </div>
        <component class="login-container-content-middle" :is="currentComponent" @onSwithClick="onSwithClick"
          @onLoginSucceed="onLoginSucceed"></component>
        <div class="login-container-content-bottom">
          <div class="login-container-content-bottom-top">
            <p>浙ICP备19032989号-2</p>
            <a class="login-container-content-bottom-top-link" :href="officalWebsite" target="_blank">访问官网</a>
          </div>
          <p class="login-container-content-bottom-top-copyright">
            Copyright © 2020 杭州八戒睡眠医学技术有限公司
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Password from "@c/login/password.vue";
import VerifyCode from "@c/login/verify-code.vue";
import { CacheTool } from "@js/cache-tool.js";
import { global } from "@js/global.js";
export default {
  components: {
    Password,
    VerifyCode,
  },

  data: () => {
    return {
      currentComponent: "Password",
      officalWebsite: LINK_URL.officalWebsite,
    };
  },
  methods: {
    onSwithClick(component) {
      this.$toast.hide();
      if (component === "VerifyCode") {
        this.currentComponent = "Password";
      } else {
        this.currentComponent = "VerifyCode";
      }
    },

    onLoginSucceed(response) {
      CacheTool.saveLoginInfo(response);
      global.token = response.token
      this.userInfoRequest();
    },

    async userInfoRequest() {
      const loginInfo = CacheTool.getLoginInfo();
      const params = {
        id: loginInfo.uid
      };
      try {
        const response = await this.$api.getUserInfo(params);
        this.$store.commit("saveAccountInfo", response);
        this.$router.push("/");
      } catch (error) {
        this.$toast.loginShowRed(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>


.login {
  background-color: #eff2f7;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-content {
      margin-top: 15%;
      min-height: rem(654);

      &-top {
        display: flex;

        &-logo {
          width: rem(76);
          height: rem(75);
        }

        &-right {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          margin-left: rem(28);

          &-title {
            color: #212773;
            font-size: rem(30);
            letter-spacing: rem(6);
            line-height: rem(42);
          }

          &-info {
            color: rgba($color: #212773, $alpha: 0.5);
            font-size: rem(16);
            line-height: rem(21);
          }
        }
      }

      &-middle {
        width: 100%;
        margin-top: rem(35);
      }

      &-bottom {
        color: #848487;
        font-size: rem(18);
        width: 100%;
        box-sizing: border-box;
        padding: rem(10) rem(16);
        margin-top: 25%;
        align-items: center;

        &-top {
          width: 100%;
          display: flex;
          justify-content: space-between;

          &-link {
            color: #848487;
            text-decoration: none;
          }

          &-copyright {
            margin-top: rem(15);
          }
        }
      }
    }
  }
}
</style>